import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loading: false,
    authenticated: localStorage.getItem("authenticated") || false,
    token: localStorage.getItem("token") || "",
    region: localStorage.getItem("region") || null,
    root_url: "https://operations-api.onrender.com",
    user: {
      vehicles: [],
      reservations: [],
      regions: [],
      employees: [],
    },
    connection: "Disconnected",
    setup: true,
    isConnectionEstablished: false,
    isRegionGathered: false,
  },
  mutations: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    unsetAuthentication(state) {
      state.authenticated = false;
      state.token = "";
      localStorage.removeItem("authenticated");
      localStorage.removeItem("token");
      state.connection = "Disconnected";
    },
    setAuthentication(state, token) {
      state.authenticated = true;
      localStorage.setItem("authenticated", true);
      state.token = token;
      localStorage.setItem("token", token);
    },
    unsetRegion(state) {
      state.region = "";
      localStorage.removeItem("region");
    },
    setRegion(state, region) {
      state.region = region;
      localStorage.setItem("region", region);
    },
    setUser(state, user) {
      state.user = user;
    },
    setConnectionStatus(state, status) {
      state.connection = status;
    },
    finishSetup(state) {
      state.setup = false;
    },
    unsetSetup(state) {
      state.setup = true;
    },
    updateReservation(state, record) {
      state.loading = true;
      console.log(record);
      // Find the index of the reservation to update
      const index = state.user.reservations.findIndex(
        (r) => r.id === record.id
      );

      // Update if found
      if (index !== -1) {
        Vue.set(state.user.reservations, index, record);
      }
      state.loading = false;
    },
    updateVehicle(state, record) {
      state.loading = true;
      // Find the index of the reservation to update
      const index = state.user.vehicles.findIndex((r) => r.vin === record.vin);

      // Update if found
      if (index !== -1) {
        Vue.set(state.user.vehicles, index, record);
      }
      state.loading = false;
    },
    updateCollection(state, record) {
      state.loading = true;
      // Find the index of the collection to update
      const index = state.user.collections.findIndex((r) => r.id === record.id);

      // Update if found
      if (index !== -1) {
        Vue.set(state.user.collections, index, record);
      }
      state.loading = false;
    },
    insertReservation(state, record) {
      state.loading = true;
      state.user.reservations.push(record);
      state.loading = false;
    },
    insertVehicle(state, record) {
      state.loading = true;
      const regionExists = state.user.regions.some(
        (region) => region.id === record.region_id
      );

      // Add the vehicle record to the vehicles array only if the region exists
      if (regionExists) {
        state.user.vehicles.push(record);
      }
      state.loading = false;
    },
    insertCollection(state, record) {
      state.loading = true;
      state.user.collections.push(record);
      state.loading = false;
    },
    deleteReservation(state, record) {
      state.loading = true;
      const index = state.user.reservations.findIndex(
        (r) => r.id === record.id
      );
      if (index !== -1) {
        state.user.reservations.splice(index, 1);
      }
      state.loading = false;
    },
    deleteVehicle(state, record) {
      state.loading = true;
      const index = state.user.vehicles.findIndex((v) => v.id === record.id);
      if (index !== -1) {
        state.user.vehicles.splice(index, 1);
      }
      state.loading = false;
    },
    deleteCollection(state, record) {
      state.loading = true;
      const index = state.user.collections.findIndex((v) => v.id === record.id);
      if (index !== -1) {
        state.user.collections.splice(index, 1);
      }
      state.loading = false;
    },
    setIsRegionGathered(state) {
      state.isRegionGathered = true;
    },

    setIsConnectionEstablished(state) {
      state.isConnectionEstablished = true;
    },
  },
  actions: {
    getUser: function ({ state, commit }) {
      axios
        .get(state.root_url + "/profile", {
          headers: {
            Authorization: state.token,
          },
        })
        .then((result) => {
          commit("setUser", result.data);
        });
    },
  },
});

export default store;
