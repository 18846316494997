<template>
  <div>
    <div class="fluid-container">
      <div class="spinner-container" v-if="loading">
        <!-- Spinner -->
        <div class="spinner-border text-primary highlighted" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div
        class="row g-0 mb-5"
        style="min-height: 65vh !important"
        v-if="!loading"
      >
        <div class="col-12 col-md-12">
          <div class="d-flex">
            <select
              class="form-control form-select w-25 ms-auto me-2 my-auto"
              :value="reservation.status"
              @change="onStatusChange($event, reservation)"
              id="offcanvas-status-input"
            >
              <option value="Pending">Pending</option>
              <option value="On Rent">On Rent</option>
              <option value="Returned">Returned</option>
              <option value="Canceled">Canceled</option>
            </select>
            <button
              type="button"
              class="btn btn-primary btn-sm me-1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="getUser"
            >
              Refresh
            </button>
            <div
              class="btn-group btn-group-sm"
              role="group"
              aria-label="Button group with nested dropdown"
            >
              <div class="btn-group" role="group">
                <button
                  type="button"
                  class="btn btn-primary btn-sm dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Action
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <button
                      class="dropdown-item"
                      @click="sendLink(reservation, 'payment')"
                      v-if="reservation.platform != 'TURO'"
                    >
                      Request Payment
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="sendLink(reservation, 'insurance')"
                      v-if="
                        reservation.platform != 'TURO' &&
                        reservation.status != 'Canceled' &&
                        reservation.status != 'Returned'
                      "
                    >
                      Request Insurance
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="sendLink(reservation, 'license')"
                      v-if="
                        reservation.platform != 'TURO' &&
                        reservation.status != 'Canceled' &&
                        reservation.status != 'Returned'
                      "
                    >
                      Request License Verification
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="sendLink(reservation, 'repo_warning')"
                      v-if="
                        reservation.platform != 'TURO' &&
                        reservation.status != 'Canceled' &&
                        reservation.status != 'Returned'
                      "
                    >
                      Send Repo Warning (24h)
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="sendLink(reservation, 'damage_waiver')"
                      v-if="
                        reservation.platform != 'TURO' &&
                        reservation.status == 'Pending' &&
                        user.damage_waiver == null
                      "
                    >
                      Request Damage Waiver
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="sendLink(reservation, 'extension')"
                      v-if="
                        reservation.platform != 'TURO' &&
                        (reservation.status == 'On Rent' ||
                          reservation.status == 'Pending')
                      "
                    >
                      Send Extension Form
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="sendLink(reservation, 'send_key')"
                      v-if="
                        reservation.status != 'Canceled' &&
                        reservation.status != 'Returned' &&
                        reservation.vehicle_vin
                      "
                    >
                      Send Digital Key
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="sendLink(reservation, 'revoke_key')"
                      v-if="reservation.vehicle_vin"
                    >
                      Revoke Digital Key
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
          <div class="text-center mx-auto">
            <h1 class="mt-5 mb-3">
              <span class="highlighted">
                {{ reservation.platform_id }}
              </span>
            </h1>
            <p>
              Internal ID: {{ reservation.id }} | Prefixed ID:
              {{ reservation.prefixed_id }} | Platform ID:
              {{ reservation.platform_id }}
            </p>
            <ReservationCard
              :reservation="reservation"
              :user="user"
            ></ReservationCard>
            <a
              v-if="reservation.platform == 'HQ'"
              :href="
                'https://resla.retool.com/apps/a0249dce-1f6f-11ee-bdfd-171b31605a04/Support%20Page?id=' +
                reservation.platform_id
              "
              target="_blank"
              class="btn btn-primary btn-sm"
              >Support Dashboard</a
            >
            <a
              v-if="reservation.platform == 'HQ'"
              :href="
                'https://resla.resla.com/car-rental/reservations/step3?id=' +
                reservation.platform_id.replace('HQ-', '')
              "
              target="_blank"
              class="btn btn-primary btn-sm ms-1"
              >Go to Platform</a
            >
            <a
              v-if="reservation.platform == 'TURO'"
              :href="
                'https://turo.com/us/en/reservation/' +
                reservation.platform_id.slice(5)
              "
              target="_blank"
              class="btn btn-primary btn-sm"
              >Go to Platform</a
            >
          </div>
        </div>

        <div class="col-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
          <ul
            class="nav nav-pills mb-3 mt-5 flex-column flex-sm-row"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item flex-sm-fill" role="presentation">
              <button
                class="nav-link w-100 active"
                id="pills-vehicle-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-vehicle"
                type="button"
                role="tab"
                aria-controls="pills-vehicle"
                aria-selected="false"
              >
                Vehicle
              </button>
            </li>
            <li class="nav-item flex-sm-fill" role="presentation">
              <button
                class="nav-link w-100"
                id="pills-user-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-user"
                type="button"
                role="tab"
                aria-controls="pills-user"
                aria-selected="true"
              >
                Renter
              </button>
            </li>
            <li class="nav-item flex-sm-fill" role="presentation">
              <button
                class="nav-link w-100"
                id="pills-images-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-images"
                type="button"
                role="tab"
                aria-controls="pills-images"
                aria-selected="false"
              >
                Images
              </button>
            </li>
            <li class="nav-item flex-sm-fill" role="presentation">
              <button
                class="nav-link w-100"
                id="pills-keys-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-keys"
                type="button"
                role="tab"
                aria-controls="pills-keys"
                aria-selected="false"
              >
                Digital Keys
              </button>
            </li>

            <li class="nav-item flex-sm-fill" role="presentation">
              <button
                class="nav-link w-100"
                id="pills-insurance-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-insurance"
                type="button"
                role="tab"
                aria-controls="pills-insurance"
                aria-selected="false"
              >
                Insurance
              </button>
            </li>
            <li class="nav-item flex-sm-fill" role="presentation">
              <button
                class="nav-link w-100"
                id="pills-billing-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-billing"
                type="button"
                role="tab"
                aria-controls="pills-billing"
                aria-selected="false"
              >
                Billing
              </button>
            </li>
            <li class="nav-item flex-sm-fill" role="presentation">
              <a
                class="nav-link w-100"
                target="_blank"
                v-if="user"
                :href="
                  'https://app.frontapp.com/inboxes/teams/folders/14645857/unassigned/64278391329/search/local/' +
                  encodeURIComponent(user.phone)
                "
              >
                Messages
              </a>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade"
              id="pills-user"
              role="tabpanel"
              aria-labelledby="pills-user-tab"
              tabindex="0"
            >
              <!-- Renter -->
              <h5
                class="fw-bold mt-5 d-flex justify-content-between align-items-center mb-3"
              >
                Renter
                <span
                  class="badge badge-tertiary"
                  v-if="
                    reservation.confirm_license == true &&
                    reservation.platform != 'TURO'
                  "
                  >Confirmed</span
                >
                <button
                  type="button"
                  class="btn btn-primary btn-sm ms-auto"
                  v-on:click="confirm(reservation, 'License')"
                  v-if="
                    reservation.platform != 'TURO' &&
                    reservation.confirm_license == false
                  "
                >
                  Mark as Complete
                </button>
              </h5>
              <div class="row g-0">
                <p v-if="!loading && _.isEmpty(user)">
                  No renter assigned to the reservation.
                </p>
                <div class="col-md-6">
                  <div
                    class="placeholder-wave position-relative p-1"
                    v-if="
                      !loading &&
                      !_.isEmpty(user) &&
                      user.selfie == null &&
                      reservation.platform != 'TURO'
                    "
                  >
                    <span
                      class="placeholder col-12 rounded"
                      style="height: 55vh"
                    ></span>
                    <span
                      class="badge bg-secondary position-absolute top-50 start-50 translate-middle"
                    >
                      Waiting for upload
                    </span>
                  </div>
                  <img
                    class="w-100"
                    v-if="
                      isImage(user.front_license) &&
                      !_.isEmpty(user) &&
                      reservation.platform != 'TURO'
                    "
                    :src="user.front_license"
                    alt="Front License"
                  />
                  <p
                    class="text-center mb-0"
                    v-if="
                      !loading &&
                      !_.isEmpty(user) &&
                      user.selfie == null &&
                      reservation.platform != 'TURO'
                    "
                  >
                    License (Front)
                  </p>
                </div>
                <div class="col-md-6">
                  <div
                    class="placeholder-wave position-relative p-1"
                    v-if="
                      !loading &&
                      !_.isEmpty(user) &&
                      user.front_license == null &&
                      reservation.platform != 'TURO'
                    "
                  >
                    <span
                      class="placeholder col-12 rounded"
                      style="height: 55vh"
                    ></span>
                    <span
                      class="badge bg-secondary position-absolute top-50 start-50 translate-middle"
                    >
                      Waiting for upload
                    </span>
                  </div>
                  <img
                    class="w-100"
                    v-if="
                      isImage(user.selfie) &&
                      !_.isEmpty(user) &&
                      reservation.platform != 'TURO'
                    "
                    :src="user.selfie"
                    alt="Selfie"
                  />
                  <p
                    class="text-center mb-0"
                    v-if="
                      !loading &&
                      !_.isEmpty(user) &&
                      user.front_license == null &&
                      reservation.platform != 'TURO'
                    "
                  >
                    Selfie
                  </p>
                </div>
              </div>

              <div class="mt-3" v-if="!loading && !_.isEmpty(user)">
                <div class="d-flex flex-grow-1 align-items-center">
                  <span class="align-items-center flex-grow-1 my-auto">
                    <h5 class="mb-0">
                      {{ user.first_name }} {{ user.last_name }}
                      <span class="badge badge-tertiary">{{
                        user.license_status
                      }}</span>
                    </h5>

                    <p class="mb-0 mt-1">
                      <a
                        v-if="!loading && !_.isEmpty(user)"
                        :href="'tel:' + user.phone"
                        >{{ user.phone }}</a
                      >
                      <span
                        v-if="
                          !loading && !_.isEmpty(user) && user.email != null
                        "
                      >
                        |
                      </span>
                      <a
                        v-if="
                          !loading && !_.isEmpty(user) && user.email != null
                        "
                        :href="'mailto:' + user.email"
                        >{{ user.email }}</a
                      >
                    </p>
                  </span>
                  <div class="col-4 d-flex">
                    <button
                      @click="
                        open(
                          'https://turo.com/us/en/reservation/' +
                            reservation.platform_id.slice(5)
                        )
                      "
                      v-if="reservation.platform == 'TURO'"
                      target="_blank"
                      class="btn btn-sm my-auto ms-auto btn-primary"
                    >
                      Go to Platform
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade show active"
              id="pills-vehicle"
              role="tabpanel"
              aria-labelledby="pills-vehicle-tab"
              tabindex="0"
            >
              <!-- Assigned Vehicle -->
              <h5 class="fw-bold mt-5">Assigned Vehicle</h5>
              <p v-if="!vehicle">No vehicle assigned to the reservation.</p>

              <div class="card shadow rounded-3" v-if="vehicle">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <img
                      :src="
                        $store.state.user.models.find(
                          (r) => r.name === vehicle.model
                        )
                          ? $store.state.user.models.find(
                              (r) => r.name === vehicle.model
                            ).public_image_link
                          : null
                      "
                      class="rounded me-2"
                      alt="Vehicle Image"
                      style="height: 5vh"
                    />
                    <div class="d-flex flex-grow-1 align-items-center">
                      <span class="align-items-center flex-grow-1">
                        <h5 class="mb-0">
                          {{ vehicle.model }} ({{ vehicle.vin.slice(-6) }})
                        </h5>
                        <p class="mb-0 mt-0">
                          {{
                            $store.state.user.locations.find(
                              (location) => location.id === vehicle.location_id
                            )
                              ? $store.state.user.locations.find(
                                  (location) =>
                                    location.id === vehicle.location_id
                                ).name
                              : ""
                          }}
                        </p>
                        <p class="mb-0">
                          <span class="badge badge-secondary me-1">
                            {{
                              vehicle.license_plate ? vehicle.license_plate : ""
                            }}
                          </span>
                          <span class="badge">
                            {{
                              $store.state.user.regions.find(
                                (r) => r.id === vehicle.region_id
                              )
                                ? $store.state.user.regions.find(
                                    (r) => r.id === vehicle.region_id
                                  ).prefix
                                : ""
                            }}
                          </span>
                          <span class="badge badge-tertiary ms-1">
                            {{ vehicle.status }}
                          </span>
                        </p>
                      </span>
                      <div class="col-4 d-flex">
                        <button
                          class="btn btn-sm my-auto ms-auto btn-primary"
                          v-on:click="unassignVehicle(reservation, vehicle)"
                          v-if="reservation.platform !== 'TURO'"
                        >
                          Unassign
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <!-- Available Vehicles-->
                <h5
                  class="fw-bold mt-5 mb-3 d-flex justify-content-center"
                  v-if="
                    reservation.status != 'Canceled' &&
                    reservation.status != 'Returned'
                  "
                >
                  Available Vehicles
                  <input
                    type="text"
                    class="form-control w-auto ms-auto mt-auto d-none d-md-block"
                    placeholder="Search by VIN, Model, or License Plate"
                    v-model="searchQuery"
                  />
                </h5>
                <input
                  type="text"
                  class="form-control w-100 d-md-none mt-3 mb-3"
                  placeholder="Search by VIN, Model, or License Plate"
                  v-model="searchQuery"
                  v-if="
                    reservation.status != 'Canceled' &&
                    reservation.status != 'Returned'
                  "
                />
                <p
                  v-if="
                    !$store.state.user.vehicles.length &&
                    reservation.status != 'Canceled' &&
                    reservation.status != 'Returned'
                  "
                >
                  Looks like you don't have access to any vehicles! Contact your
                  supervisor to get access.
                </p>
                <span
                  v-if="
                    reservation.status != 'Canceled' &&
                    reservation.status != 'Returned'
                  "
                >
                  <div
                    class="card shadow rounded-3 mt-3 mb-3"
                    v-for="v in vehicles.slice(0, 5)"
                    :key="'alternative-' + v.id"
                  >
                    <div class="card-body">
                      <div class="row g-0">
                        <div class="d-flex align-items-center col-8">
                          <img
                            :src="
                              $store.state.user.models.find(
                                (r) => r.name === v.model
                              )
                                ? $store.state.user.models.find(
                                    (r) => r.name === v.model
                                  ).public_image_link
                                : null
                            "
                            class="rounded me-2"
                            alt="Vehicle Image"
                            style="height: 5vh"
                          />
                          <div class="d-flex flex-grow-1 align-items-center">
                            <span class="align-items-center flex-grow-1">
                              <h5 class="mb-0">
                                {{ v.model }} ({{ v.vin.slice(-6) }})
                              </h5>
                              <p class="mb-0 mt-0">
                                {{
                                  $store.state.user.locations.find(
                                    (location) => location.id === v.location_id
                                  )
                                    ? $store.state.user.locations.find(
                                        (location) =>
                                          location.id === v.location_id
                                      ).name
                                    : ""
                                }}
                              </p>
                              <p class="mb-0">
                                <span class="badge badge-secondary me-1">
                                  {{ v.license_plate }}
                                </span>
                                <span class="badge">
                                  {{
                                    $store.state.user.regions.find(
                                      (r) => r.id === v.region_id
                                    )
                                      ? $store.state.user.regions.find(
                                          (r) => r.id === v.region_id
                                        ).prefix
                                      : ""
                                  }}
                                </span>
                                <span class="badge badge-tertiary ms-1">
                                  {{ v.status }}
                                </span>
                              </p>
                            </span>
                          </div>
                        </div>
                        <div class="col-4 d-flex">
                          <button
                            class="btn btn-sm my-auto ms-auto btn-primary"
                            v-on:click="assignVehicle(reservation, v)"
                          >
                            <span
                              v-if="
                                reservation.platform == 'TURO' &&
                                reservation.status != 'Canceled' &&
                                reservation.status != 'Returned'
                              "
                              >Swap</span
                            >
                            <span
                              v-if="
                                reservation.platform == 'HQ' &&
                                reservation.status != 'Canceled' &&
                                reservation.status != 'Returned'
                              "
                              >Assign</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
                <p v-if="!vehicles.length">No vehicles available.</p>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="pills-images"
              role="tabpanel"
              aria-labelledby="pills-images-tab"
              tabindex="0"
            >
              <!-- Images -->
              <h5
                class="fw-bold mt-5 d-flex justify-content-between align-items-center"
              >
                Pickup
                <span>
                  <span
                    class="badge badge-tertiary"
                    v-if="
                      reservation.upload_pickup_photos == true &&
                      reservation.platform == 'TURO' &&
                      user.internal_pickups
                    "
                    >Uploaded</span
                  >
                  <button
                    type="button"
                    class="btn btn-primary btn-sm ms-auto"
                    v-on:click="complete(reservation, 'Pickup')"
                    v-if="
                      reservation.upload_pickup_photos == false &&
                      reservation.platform == 'TURO' &&
                      user.internal_pickups
                    "
                  >
                    Mark as Complete
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm ms-1"
                    v-if="user.internal_pickups"
                    @click="
                      zip(
                        user.internal_pickups[0].Key.substring(
                          0,
                          user.internal_pickups[0].Key.lastIndexOf('/') + 1
                        ),
                        reservation.platform_id,
                        'pickup'
                      )
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      height="1rem"
                    >
                      <path
                        d="M3 19H21V21H3V19ZM13 9H20L12 17L4 9H11V1H13V9Z"
                      ></path>
                    </svg>
                  </button>
                </span>
              </h5>
              <p v-if="!user.internal_pickups">
                No images associated with the internal pickup process.
              </p>
              <div class="table-responsive" v-if="user.internal_pickups">
                <table class="table table-striped mb-3">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Last Modified</th>
                      <th scope="col" class="text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="picture in _.sortBy(
                        user.internal_pickups,
                        'LastModified'
                      )"
                      :key="
                        'offcanvas-img-' +
                        picture.ETag +
                        '-' +
                        'reservation-' +
                        reservation.id
                      "
                    >
                      <td class="align-middle">
                        {{
                          picture.Key.substring(
                            picture.Key.lastIndexOf("/") + 1
                          ).replace(/\.jpg$/i, "")
                        }}
                      </td>
                      <td class="align-middle">
                        {{
                          new Date(picture.LastModified).toLocaleDateString(
                            "en-US",
                            {
                              timeZone: $store.state.user.regions.find((r) => {
                                return r.id == $store.state.region;
                              }).timezone,
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )
                        }}
                      </td>
                      <td class="text-end align-middle">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="download(picture.Key)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="white"
                            height="1rem"
                          >
                            <path
                              d="M3 19H21V21H3V19ZM13 9H20L12 17L4 9H11V1H13V9Z"
                            ></path>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h5
                class="fw-bold mt-5 d-flex justify-content-between align-items-center"
              >
                Return
                <span>
                  <span
                    class="badge badge-tertiary"
                    v-if="
                      reservation.upload_return_photos == true &&
                      reservation.platform == 'TURO' &&
                      user.internal_returns
                    "
                    >Uploaded</span
                  >
                  <button
                    type="button"
                    class="btn btn-primary btn-sm ms-auto"
                    v-on:click="complete(reservation, 'Return')"
                    v-if="
                      reservation.upload_return_photos == false &&
                      reservation.platform == 'TURO' &&
                      user.internal_returns
                    "
                  >
                    Mark as Complete
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm ms-1"
                    v-if="user.internal_returns"
                    @click="
                      zip(
                        user.internal_returns[0].Key.substring(
                          0,
                          user.internal_returns[0].Key.lastIndexOf('/') + 1
                        ),
                        reservation.platform_id,
                        'return'
                      )
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      height="1rem"
                    >
                      <path
                        d="M3 19H21V21H3V19ZM13 9H20L12 17L4 9H11V1H13V9Z"
                      ></path>
                    </svg>
                  </button>
                </span>
              </h5>
              <p v-if="!user.internal_returns">
                No images associated with internal return process.
              </p>
              <div class="table-responsive" v-if="user.internal_returns">
                <table class="table table-striped mb-3">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Last Modified</th>
                      <th scope="col" class="text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="picture in _.sortBy(
                        user.internal_returns,
                        'LastModified'
                      )"
                      :key="
                        'offcanvas-img-' +
                        picture.ETag +
                        '-' +
                        'reservation-' +
                        reservation.id
                      "
                    >
                      <td class="align-middle">
                        {{
                          picture.Key.substring(
                            picture.Key.lastIndexOf("/") + 1
                          ).replace(/\.jpg$/i, "")
                        }}
                      </td>
                      <td class="align-middle">
                        {{
                          new Date(picture.LastModified).toLocaleDateString(
                            "en-US",
                            {
                              timeZone: $store.state.user.regions.find((r) => {
                                return r.id == $store.state.region;
                              }).timezone,
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )
                        }}
                      </td>
                      <td class="text-end align-middle">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="download(picture.Key)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="white"
                            height="1rem"
                          >
                            <path
                              d="M3 19H21V21H3V19ZM13 9H20L12 17L4 9H11V1H13V9Z"
                            ></path>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h5 class="fw-bold mt-5">Check In</h5>
              <p>No images associated with the customer check in process.</p>

              <h5 class="fw-bold mt-5">Check Out</h5>
              <p>No images associated with the customer checkout process.</p>
            </div>
            <div
              class="tab-pane fade"
              id="pills-keys"
              role="tabpanel"
              aria-labelledby="pills-keys-tab"
              tabindex="0"
            >
              <!-- Keys -->
              <h5 class="fw-bold mt-5">Digital Keys</h5>
              <p v-if="user.keys.length < 1">
                No keys are associated with the reservation.
              </p>
              <div class="table-responsive" v-if="user.keys.length > 0">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Created</th>
                      <th>Start</th>
                      <th>End</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="key of _.sortBy(user.keys, 'created_at')"
                      :key="'key-' + key.id"
                    >
                      <td class="align-middle">
                        {{ key.external_id }}
                      </td>

                      <td
                        class="align-middle"
                        style="whitespace: no-wrap !important"
                      >
                        {{
                          new Date(key.created_at).toLocaleDateString("en-US", {
                            timeZone: $store.state.user.regions.find((r) => {
                              return r.id == $store.state.region;
                            }).timezone,
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        }}
                      </td>
                      <td
                        class="align-middle"
                        style="whitespace: no-wrap !important"
                      >
                        {{
                          new Date(key.pickup_at).toLocaleDateString("en-US", {
                            timeZone: $store.state.user.regions.find((r) => {
                              return r.id == $store.state.region;
                            }).timezone,
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        }}
                      </td>
                      <td
                        class="align-middle"
                        style="whitespace: no-wrap !important"
                      >
                        {{
                          new Date(key.return_at).toLocaleDateString("en-US", {
                            timeZone: $store.state.user.regions.find((r) => {
                              return r.id == $store.state.region;
                            }).timezone,
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        }}
                      </td>
                      <td class="align-middle">
                        <span
                          class="badge badge-tertiary"
                          v-if="
                            key.active == false ||
                            new Date(
                              new Date(key.return_at).toLocaleString('en-US', {
                                timeZone: $store.state.user.regions.find(
                                  (r) => {
                                    return r.id == $store.state.region;
                                  }
                                ).timezone,
                              })
                            ) <
                              new Date(
                                new Date().toLocaleString('en-US', {
                                  timeZone: $store.state.user.regions.find(
                                    (r) => {
                                      return r.id == $store.state.region;
                                    }
                                  ).timezone,
                                })
                              )
                          "
                          >Expired</span
                        >
                        <span class="badge badge-primary" v-else>Active</span>
                      </td>
                      <td class="align-middle">
                        <button
                          class="btn btn-sm btn-primary"
                          v-if="
                            vehicle &&
                            key.active == true &&
                            new Date(
                              new Date(key.return_at).toLocaleString('en-US', {
                                timeZone: $store.state.user.regions.find(
                                  (r) => {
                                    return r.id == $store.state.region;
                                  }
                                ).timezone,
                              })
                            ) >
                              new Date(
                                new Date().toLocaleString('en-US', {
                                  timeZone: $store.state.user.regions.find(
                                    (r) => {
                                      return r.id == $store.state.region;
                                    }
                                  ).timezone,
                                })
                              )
                          "
                          @click="
                            copyToClipboard(
                              'Hi ' +
                                user.first_name +
                                ', your digital key for your ' +
                                vehicle.color +
                                ' ' +
                                vehicle.model +
                                ' with license plate ' +
                                vehicle.license_plate +
                                ' is ready. Click here for access: https://key.resla.com/#token=' +
                                key.token
                            )
                          "
                        >
                          Copy
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-insurance"
              role="tabpanel"
              aria-labelledby="pills-insurance-tab"
              tabindex="0"
            >
              <!-- Insurance -->
              <h5
                class="fw-bold mt-5 d-flex justify-content-between align-items-center mb-3"
              >
                Insurance
                <span
                  class="badge badge-tertiary"
                  v-if="
                    reservation.platform != 'TURO' &&
                    reservation.confirm_insurance == true
                  "
                  >Confirmed</span
                >
                <button
                  type="button"
                  class="btn btn-primary btn-sm ms-auto"
                  v-on:click="confirm(reservation, 'Insurance')"
                  v-if="
                    reservation.platform != 'TURO' &&
                    reservation.confirm_insurance == false
                  "
                >
                  Mark as Complete
                </button>
              </h5>
              <p
                v-if="
                  !loading &&
                  (_.isEmpty(user) ||
                    reservation.platform == 'TURO' ||
                    user.insurance_image == null)
                "
              >
                No insurance associated with the user.
              </p>
              <div
                class="row g-0"
                v-if="
                  reservation.platform != 'TURO' &&
                  reservation.damage_waiver == false
                "
              >
                <div class="col-md-6">
                  <div
                    class="placeholder-wave position-relative p-1"
                    v-if="
                      !loading &&
                      !_.isEmpty(user) &&
                      user.insurance == null &&
                      reservation.platform != 'TURO'
                    "
                  >
                    <span
                      class="placeholder col-12 rounded"
                      style="height: 55vh"
                    ></span>
                    <span
                      class="badge bg-secondary position-absolute top-50 start-50 translate-middle"
                    >
                      Waiting for upload
                    </span>
                  </div>
                  <p
                    class="text-center"
                    v-if="
                      !loading &&
                      !_.isEmpty(user) &&
                      user.insurance == null &&
                      reservation.platform != 'TURO'
                    "
                  >
                    Insurance Card
                  </p>
                </div>
              </div>
              <img
                class="w-100"
                v-if="
                  isImage(user.insurance) &&
                  !_.isEmpty(user) &&
                  user.insurance != null
                "
                :src="user.insurance"
                alt="Insurance"
              />
              <object
                v-if="
                  !isImage(user.insurance) &&
                  !_.isEmpty(user) &&
                  user.insurance != null
                "
                :data="user.insurance"
                type="application/pdf"
                style="width: 100%; height: 100vh"
              ></object>
              <!-- Damage Waiver -->
              <h5
                class="fw-bold mt-5 mb-3"
                v-if="reservation.platform != 'TURO'"
              >
                Damage Waiver
                <span
                  class="badge badge-tertiary"
                  v-if="
                    reservation.platform != 'TURO' &&
                    user.damage_waiver == null &&
                    reservation.damage_waiver == true
                  "
                  >Sent</span
                >
              </h5>
              <div class="row g-0">
                <p
                  v-if="
                    reservation.platform != 'TURO' &&
                    (user.damage_waiver == null ||
                      reservation.damage_waiver == false)
                  "
                >
                  No damage waiver associated with the reservation.
                </p>
              </div>
              <object
                v-if="
                  !_.isEmpty(user) &&
                  user.damage_waiver != null &&
                  reservation.damage_waiver == true
                "
                :data="user.damage_waiver"
                type="application/pdf"
                style="width: 100%; height: 100vh"
              ></object>
            </div>
            <div
              class="tab-pane fade"
              id="pills-billing"
              role="tabpanel"
              aria-labelledby="pills-billing-tab"
              tabindex="0"
            >
              <!-- Collections -->
              <h5
                class="fw-bold mt-5 d-flex align-items-center"
                v-if="reservation.collections_status != null"
              >
                <span>Sent to Collections</span>
                <router-link
                  class="btn btn-primary ms-auto btn-sm"
                  tag="button"
                  to="/collections"
                  >View</router-link
                >
              </h5>
              <p v-if="reservation.collections_status != null">
                This reservation was sent to collections and is in the status
                <span class="badge badge-tertiary">{{
                  reservation.collections_status
                }}</span>
              </p>
              <!-- Billing -->
              <h5 class="fw-bold mt-5 d-flex align-items-center">
                <span>Charging Method</span>
                <select
                  class="form-control form-select w-25 ms-auto me-2 my-auto"
                  :value="reservation.charging_method"
                  @change="onChargingMethodChange($event, reservation)"
                  id="offcanvas-status-input"
                  v-if="
                    reservation.status != 'Canceled' &&
                    reservation.status != 'Returned'
                  "
                >
                  <option value="Manual">Manual</option>
                  <option value="Standard Fleet">Standard Fleet</option>
                  <option value="Payment Responsible">
                    Payment Responsible
                  </option>
                </select>
              </h5>
              <p>
                This reservation was set to use
                <span v-if="reservation.charging_method == 'Standard Fleet'"
                  >Standard Fleet's automatic billing system associated with the
                  renters digital key.</span
                >
                <span v-if="reservation.charging_method == 'Manual'"
                  >manual billing. Please pull supercharging invoices from
                  standard fleet portal and manually charge the renter.</span
                >
                <span
                  v-if="reservation.charging_method == 'Payment Responsible'"
                  >payment responsibility via Tesla. There is no further action
                  required, however, please remove the renter from payment
                  responsibility upon trip completion.</span
                >
              </p>
              <h5 class="fw-bold mt-5 mb-3">
                External Transactions
                <div
                  class="float-end"
                  v-if="user.charging_transactions.reservation != null"
                >
                  <span
                    class="badge badge-red"
                    v-if="
                      user.charging_transactions.reservation
                        .outstanding_balance > 0
                    "
                    >{{
                      new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(
                        parseFloat(
                          user.charging_transactions.reservation
                            .outstanding_balance
                        ) / 100
                      )
                    }}
                  </span>
                  <span
                    class="badge badge-tertiary"
                    v-if="
                      user.charging_transactions.reservation
                        .outstanding_balance == 0
                    "
                    >{{
                      new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(
                        parseFloat(
                          user.charging_transactions.reservation
                            .outstanding_balance
                        ) / 100
                      )
                    }}
                  </span>
                  <a
                    :href="
                      'https://fleetweb.standardfleet.com/supercharging?vins=' +
                      reservation.vehicle_vin +
                      '&startDate=' +
                      new Date(reservation.pickup_at).toISOString() +
                      '&endDate=' +
                      new Date(reservation.return_at).toISOString()
                    "
                    target="_blank"
                    class="btn btn-primary btn-sm my-auto ms-1"
                    v-if="reservation.vehicle_vin"
                  >
                    View
                  </a>
                </div>
              </h5>
              <p
                v-if="
                  _.isEmpty(user) || user.charging_transactions.items.length < 1
                "
              >
                No external transactions associated with the reservation.
              </p>
              <div class="table-responsive">
                <table
                  class="table table-striped"
                  v-if="
                    !loading &&
                    !_.isEmpty(user) &&
                    user.charging_transactions.items.length > 0
                  "
                >
                  <thead>
                    <tr>
                      <!--<th>Internal ID</th>-->
                      <th>External ID</th>
                      <th>Cost</th>
                      <th>Amount</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Created</th>
                      <th>Tesla ID</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(transactions, external_id, index) of _.groupBy(
                      user.charging_transactions.items
                        .map((transaction) => ({
                          ...transaction,
                          created_at: new Date(transaction.date),
                        }))
                        .sort((a, b) => b.date - a.date),
                      'reservation_charge_id'
                    )"
                    :key="
                      'transaction-external-id-' + external_id + '-' + index
                    "
                  >
                    <tr
                      v-for="transaction of transactions"
                      :key="'transaction-' + transaction.id"
                    >
                      <td class="align-middle" style="display: none !important">
                        {{ transaction.id }}
                      </td>
                      <td class="align-middle">
                        {{ transaction.reservation_charge_id }}
                      </td>
                      <td class="align-middle">
                        <span v-if="transaction.amount_before_upcharge">
                          {{
                            new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(
                              parseFloat(transaction.amount_before_upcharge) /
                                100
                            )
                          }}</span
                        >
                      </td>
                      <td class="align-middle">
                        <span>
                          {{
                            new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(parseFloat(transaction.amount) / 100)
                          }}</span
                        >
                      </td>
                      <td class="align-middle">
                        <span
                          class="badge badge-tertiary"
                          v-if="transaction.category == 'RESERVE'"
                          >Authorization</span
                        >
                        <span
                          class="badge badge-tertiary"
                          v-if="transaction.category == 'CHARGING'"
                          >Charge</span
                        >
                        <span
                          class="badge badge-tertiary"
                          v-if="transaction.category == 'REFUEL'"
                          >Refuel</span
                        >
                        <span
                          class="badge badge-tertiary"
                          v-if="transaction.category == 'MILEAGE'"
                          >Mileage</span
                        >
                      </td>
                      <td class="align-middle">
                        <span
                          class="badge badge-red"
                          v-if="
                            transaction.status && transaction.status == 'FAIL'
                          "
                        >
                          {{
                            transaction.status.charAt(0).toUpperCase() +
                            transaction.status.slice(1).toLowerCase()
                          }}
                        </span>
                        <span
                          class="badge badge-tertiary"
                          v-if="
                            transaction.status &&
                            transaction.status == 'SUCCESS'
                          "
                        >
                          {{
                            transaction.status.charAt(0).toUpperCase() +
                            transaction.status.slice(1).toLowerCase()
                          }}
                        </span>
                        <span
                          class="badge badge-yellow"
                          v-if="
                            transaction.status && transaction.status == 'CANCEL'
                          "
                        >
                          {{
                            transaction.status.charAt(0).toUpperCase() +
                            transaction.status.slice(1).toLowerCase()
                          }}
                        </span>
                        <span
                          class="badge badge-yellow"
                          v-if="
                            transaction.status &&
                            transaction.status == 'PENDING'
                          "
                        >
                          {{
                            transaction.status.charAt(0).toUpperCase() +
                            transaction.status.slice(1).toLowerCase()
                          }}
                        </span>
                      </td>
                      <td
                        class="align-middle"
                        style="whitespace: no-wrap !important"
                      >
                        {{
                          new Date(transaction.created).toLocaleDateString(
                            "en-US",
                            {
                              timeZone: $store.state.user.regions.find((r) => {
                                return r.id == $store.state.region;
                              }).timezone,
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )
                        }}
                      </td>
                      <td class="align-middle">
                        <span v-if="transaction.category == 'CHARGING'">
                          {{ transaction.reference }}</span
                        >
                      </td>
                      <td class="align-middle text-end">
                        <span v-if="transaction.paid_by_reserve == true"
                          >Pulled from deposit
                          {{ transaction.reserve_applied_id }}
                        </span>
                      </td>
                    </tr>
                    <tr
                      v-if="
                        index !==
                        Object.keys(
                          _.groupBy(
                            user.charging_transactions.items,
                            'reservation_charge_id'
                          )
                        ).length -
                          1
                      "
                      style="background-color: white !important"
                    >
                      <td style="box-shadow: none !important">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          height="2.5vh"
                        >
                          <path
                            d="M12 3C11.175 3 10.5 3.675 10.5 4.5C10.5 5.325 11.175 6 12 6C12.825 6 13.5 5.325 13.5 4.5C13.5 3.675 12.825 3 12 3ZM12 18C11.175 18 10.5 18.675 10.5 19.5C10.5 20.325 11.175 21 12 21C12.825 21 13.5 20.325 13.5 19.5C13.5 18.675 12.825 18 12 18ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z"
                          ></path>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h5 class="fw-bold mt-5">Internal Transactions</h5>
              <div class="table-responsive">
                <table
                  class="table table-striped"
                  v-if="
                    !loading && !_.isEmpty(user) && user.transactions.length > 0
                  "
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Created</th>
                      <th>Type</th>
                      <th>Payment Method</th>
                      <th>Failure Message</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(transaction, index) of user.transactions
                        .map((transaction) => ({
                          ...transaction,
                          created_at: new Date(transaction.created_at),
                        }))
                        .sort((a, b) => b.created_at - a.created_at)"
                      :key="'transaction-' + transaction.id"
                    >
                      <td class="align-middle">
                        {{ index + 1 }}
                      </td>
                      <td class="align-middle">
                        {{
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(parseFloat(transaction.amount) / 100)
                        }}
                      </td>
                      <td class="align-middle">
                        <span
                          v-if="transaction.status == 'succeeded'"
                          class="badge badge-tertiary"
                        >
                          Success
                        </span>
                        <span
                          v-if="transaction.status == 'failed'"
                          class="badge badge-red"
                        >
                          Fail
                        </span>
                      </td>
                      <td
                        class="align-middle"
                        style="whitespace: no-wrap !important"
                      >
                        {{
                          new Date(
                            transaction.created * 1000
                          ).toLocaleDateString("en-US", {
                            timeZone: $store.state.user.regions.find((r) => {
                              return r.id == $store.state.region;
                            }).timezone,
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        }}
                      </td>
                      <td class="align-middle">
                        {{ transaction.payment_details.funding }}
                      </td>
                      <td class="align-middle">
                        {{ transaction.payment_details.network }} ({{
                          transaction.payment_details.last4
                        }})
                      </td>
                      <td
                        class="align-middle"
                        style="whitespace: nowrap !important"
                      >
                        {{ transaction.failure_message }}
                      </td>
                      <td class="align-middle text-end">
                        <a
                          :href="
                            'https://dashboard.stripe.com/payments/' +
                            transaction.payment_intent
                          "
                          class="btn btn-sm btn-primary"
                          target="_blank"
                          >View</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p
                v-if="
                  (!loading && _.isEmpty(user)) ||
                  (!loading && user.transactions.length == 0)
                "
              >
                No internal transactions associated with the reservation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationCard from "@/components/cards/ReservationCard.vue";

export default {
  name: "ReservationOffcanvas",
  components: { ReservationCard },
  props: ["id"],
  data: function () {
    return {
      searchQuery: "",
      loading: false,
      user: null,
    };
  },
  mounted: function () {
    if (this.id) {
      this.getUser();
    }
  },
  watch: {
    id: function (newId) {
      if (newId) {
        this.getUser();
      }
    },
  },
  methods: {
    open(url) {
      window.open(url, "_blank");
    },
    isImage(img) {
      return /\.(jpg|jpeg|png|gif)(\?.*)?$/i.test(img);
    },
    getUser: function () {
      this.loading = true;
      this.$axios
        .get(this.$store.state.root_url + "/user?id=" + this.id, {
          params: { auth: true },
        })
        .then((response) => {
          console.log(response);
          this.user = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    assignVehicle: function (reservation, vehicle) {
      this.$axios
        .post(
          this.$store.state.root_url + "/reservation/vehicle/assign",
          {
            id: reservation.id,
            vin: vehicle.vin,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    unassignVehicle: function (reservation, vehicle) {
      this.$axios
        .post(
          this.$store.state.root_url + "/reservation/vehicle/unassign",
          {
            id: reservation.id,
            vin: vehicle.vin,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateStatus: function (reservation, status) {
      this.$axios
        .post(
          this.$store.state.root_url + "/reservation/status",
          {
            id: reservation.id,
            status: status,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onStatusChange: function (event, reservation) {
      const newStatus = event.target.value;
      this.updateStatus(reservation, newStatus);
    },
    updateChargingMethod: function (reservation, method) {
      this.$axios
        .post(
          this.$store.state.root_url + "/reservation/charging_method",
          {
            id: reservation.id,
            method: method,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onChargingMethodChange: function (event, reservation) {
      const newMethod = event.target.value;
      this.updateChargingMethod(reservation, newMethod);
    },
    sendLink: function (reservation, message_type) {
      this.$axios
        .post(
          this.$store.state.root_url + "/reservation/send/link",
          {
            id: reservation.platform_id,
            message_type: message_type,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          this.$toast(
            "Success: We have sent your request to the backend for processing. It may take 2-3 minutes to fully process."
          );
          this.loading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.$toast("Failed: Your request failed. Please try again.");
          this.loading = false;
        });
    },
    zip: function (key, reservation_id, category) {
      this.loading = true;
      this.$axios({
        method: "get",
        url:
          this.$store.state.root_url +
          "/download/files?key=" +
          key +
          "&reservation_id=" +
          reservation_id +
          "&category=" +
          category,
        responseType: "blob", // Important for handling binary data
        params: {
          auth: true,
        },
      })
        .then((response) => {
          console.log(response);
          // Create a URL for the blob
          const url = window.URL.createObjectURL(new Blob([response.data]));
          // Create an anchor element and set the URL as the href
          const link = document.createElement("a");
          link.href = url;
          // Suggest a filename for the download
          const fileName = reservation_id + "-" + category + ".zip";
          link.setAttribute("download", fileName); // Set the download attribute
          document.body.appendChild(link);
          link.click(); // Programmatically click the link to trigger the download
          document.body.removeChild(link); // Clean up
          window.URL.revokeObjectURL(url); // Release the object URL
          var button = document.getElementById("pills-images-tab");
          if (button) button.click();
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          var button = document.getElementById("pills-images-tab");
          if (button) button.click();
          this.loading = false;
        });
    },
    download: function (key) {
      this.$axios
        .get(this.$store.state.root_url + "/download/file?key=" + key, {
          params: { auth: true },
        })
        .then((response) => {
          console.log(response);
          const url = response.data.url;
          // Create an anchor tag and set the presigned URL as the href
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", ""); // Optional: Set the download attribute to suggest a download
          document.body.appendChild(link);
          link.click(); // Programmatically click the link to start the download
          document.body.removeChild(link); // Clean up
          var button = document.getElementById("pills-images-tab");
          if (button) button.click();
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          var button = document.getElementById("pills-images-tab");
          if (button) button.click();
          this.loading = false;
        });
    },
    complete: function (reservation, category) {
      this.loading = true;
      this.$axios
        .post(
          this.$store.state.root_url + "/reservation/photos/turo",
          {
            id: reservation.id,
            category: category,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
    confirm: function (reservation, category) {
      this.loading = true;
      this.$axios
        .post(
          this.$store.state.root_url +
            (category == "License"
              ? "/reservation/license"
              : "/reservation/insurance"),
          {
            id: reservation.id,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          // Optional: Display a message or perform an action to inform the user that the text has been copied
          console.log("Copied to clipboard.");
        })
        .catch((err) => {
          console.error("Error in copying text: ", err);
        });
    },
  },
  computed: {
    reservation: function () {
      return this.$store.state.user.reservations.find(
        (r) => r.id === parseInt(this.id)
      );
    },
    vehicle: function () {
      return this.$store.state.user.vehicles.find(
        (r) => r.vin === this.reservation.vehicle_vin
      );
    },
    alternatives: function () {
      return this.$store.state.user.vehicles.slice(0, 3);
    },
    availableVehicles: function () {
      const allReservations = this.$store.state.user.reservations;
      const currentReservation = this.reservation; // The reservation being viewed

      return this.$store.state.user.vehicles
        .filter((vehicle) => vehicle.region_id === currentReservation.region_id) // Filter by region
        .filter(
          (vehicle) =>
            vehicle.status !== "Employee" &&
            vehicle.status !== "Unavailable" &&
            vehicle.status !== "Repairing" &&
            vehicle.status !== "Damaged"
        )
        .filter((vehicle) => {
          // Get a list of location IDs where the hq_id matches the pickup location ID
          const locationID = this.$store.state.user.locations.find(
            (location) =>
              location.hq_id == currentReservation.pickup_location_id
          ).id;
          console.log(locationID);

          // Check if the vehicle's location_id is in the list of matching location IDs
          return vehicle.location_id == locationID;
        })
        .filter((vehicle) => vehicle.vin !== currentReservation.vehicle_vin) // Filter out active vehicle
        .filter((vehicle) => {
          // Filter out vehicles with overlapping reservations that are not returned or canceled
          const overlappingReservations = allReservations.filter((res) => {
            // Skip if the reservation is for the same vehicle or if its status means it's not active
            if (
              res.id === currentReservation.id ||
              res.status === "Returned" ||
              res.status === "Canceled"
            ) {
              return false;
            }

            const resPickup = new Date(res.pickup_at);
            const resReturn = new Date(res.return_at);
            const currentPickup = new Date(currentReservation.pickup_at);
            const currentReturn = new Date(currentReservation.return_at);

            // Check for any overlap between the active reservation periods
            return (
              res.vehicle_vin === vehicle.vin &&
              !(resReturn < currentPickup || resPickup > currentReturn)
            );
          });

          // If there are no overlapping active reservations, the vehicle is available
          return overlappingReservations.length === 0;
        });
    },
    availableVehiclesInRegion: function () {
      const allReservations = this.$store.state.user.reservations;
      const currentReservation = this.reservation; // The reservation being viewed

      return this.$store.state.user.vehicles
        .filter((vehicle) => vehicle.region_id === currentReservation.region_id) // Filter by region
        .filter(
          (vehicle) =>
            vehicle.status !== "Employee" &&
            vehicle.status !== "Unavailable" &&
            vehicle.status !== "Repairing" &&
            vehicle.status !== "Damaged"
        )
        .filter((vehicle) => {
          // Get a list of location IDs where the hq_id matches the pickup location ID
          const locationID = this.$store.state.user.locations.find(
            (location) =>
              location.hq_id == currentReservation.pickup_location_id
          ).id;
          console.log(locationID);

          // Check if the vehicle's location_id is in the list of matching location IDs
          return vehicle.location_id != locationID;
        })
        .filter((vehicle) => vehicle.vin !== currentReservation.vehicle_vin) // Filter out active vehicle
        .filter((vehicle) => {
          // Filter out vehicles with overlapping reservations that are not returned or canceled
          const overlappingReservations = allReservations.filter((res) => {
            // Skip if the reservation is for the same vehicle or if its status means it's not active
            if (
              res.id === currentReservation.id ||
              res.status === "Returned" ||
              res.status === "Canceled"
            ) {
              return false;
            }

            const resPickup = new Date(res.pickup_at);
            const resReturn = new Date(res.return_at);
            const currentPickup = new Date(currentReservation.pickup_at);
            const currentReturn = new Date(currentReservation.return_at);

            // Check for any overlap between the active reservation periods
            return (
              res.vehicle_vin === vehicle.vin &&
              !(resReturn < currentPickup || resPickup > currentReturn)
            );
          });

          // If there are no overlapping active reservations, the vehicle is available
          return overlappingReservations.length === 0;
        });
    },
    vehicles: function () {
      const searchQuery = this.searchQuery.toLowerCase().trim();
      return this.availableVehicles
        .filter((vehicle) => {
          // Filter based on VIN, Model, or License Plate
          const vin = vehicle.vin.slice(-6).toLowerCase();
          const model = vehicle.model.toLowerCase();
          const licensePlate = vehicle.license_plate
            ? vehicle.license_plate.toLowerCase()
            : "";
          return (
            vin.includes(searchQuery) ||
            model.includes(searchQuery) ||
            licensePlate.includes(searchQuery)
          );
        })
        .sort((a, b) => {
          return a.vin.localeCompare(b.vin);
        });
    },
  },
};
</script>

<style scoped>
.nav-link {
  color: black;
}
.spinner-container {
  position: absolute; /* or 'fixed' if you want it relative to the viewport */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white !important;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
}

@media (max-width: 767.98px) {
  #offcanvas-status-input {
    width: 50% !important;
  }
}
</style>
