<template>
  <div>
    <div class="container">
      <!-- Reservations Table -->
      <div class="row g-0">
        <div class="col-12">
          <h1
            class="display-5 mt-5 mb-3 d-flex justify-content-center align-items-center"
          >
            <span class="highlighted me-auto">Reservations.</span>
            <div class="btn-group align-middle mt-auto me-2" role="group">
              <button
                type="button"
                class="btn btn-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Action
              </button>
              <ul class="dropdown-menu">
                <li class="mt-2">
                  <a class="dropdown-item" @click="reset()">Reset</a>
                </li>
                <p class="p-2 pb-0 mb-2">Filter by Pickups</p>

                <li>
                  <a
                    class="dropdown-item"
                    @click="
                      setPickupDateRange({
                        target: {
                          value: -1,
                        },
                      })
                    "
                    >Yesterday</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    @click="
                      setPickupDateRange({
                        target: {
                          value: 0,
                        },
                      })
                    "
                    >Today</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    @click="
                      setPickupDateRange({
                        target: {
                          value: 1,
                        },
                      })
                    "
                    >Tomorrow</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    @click="
                      setPickupDateRange({
                        target: {
                          value: 2,
                        },
                      })
                    "
                    >In 2 Days</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    @click="
                      setPickupDateRange({
                        target: {
                          value: 3,
                        },
                      })
                    "
                    >In 3 Days</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    @click="
                      setPickupDateRange({
                        target: {
                          value: 4,
                        },
                      })
                    "
                    >In 4 Days</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    @click="
                      setPickupDateRange({
                        target: {
                          value: 5,
                        },
                      })
                    "
                    >In 5 Days</a
                  >
                </li>
                <p class="p-2 pb-0 mb-2">Filter by Returns</p>
                <li>
                  <a
                    class="dropdown-item"
                    @click="
                      setReturnDateRange({
                        target: {
                          value: -1,
                        },
                      })
                    "
                    >Yesterday</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    @click="
                      setReturnDateRange({
                        target: {
                          value: 0,
                        },
                      })
                    "
                    >Today</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    @click="
                      setReturnDateRange({
                        target: {
                          value: 1,
                        },
                      })
                    "
                    >Tomorrow</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    @click="
                      setReturnDateRange({
                        target: {
                          value: 2,
                        },
                      })
                    "
                    >In 2 Days</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    @click="
                      setReturnDateRange({
                        target: {
                          value: 3,
                        },
                      })
                    "
                    >In 3 Days</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    @click="
                      setReturnDateRange({
                        target: {
                          value: 4,
                        },
                      })
                    "
                    >In 4 Days</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    @click="
                      setReturnDateRange({
                        target: {
                          value: 5,
                        },
                      })
                    "
                    >In 5 Days</a
                  >
                </li>
              </ul>
            </div>

            <button
              class="btn btn-primary mt-auto me-2"
              data-bs-toggle="offcanvas"
              data-bs-target="#reservationsOffcanvas"
            >
              Filters
            </button>

            <input
              type="text"
              class="form-control w-auto mt-auto me-2 d-none d-md-block"
              placeholder="Search by Reservation, Customer, or Vehicle"
              v-model="searchQuery"
            />
          </h1>
          <label class="d-md-none">Search:</label>
          <input
            type="text"
            class="form-control w-100 d-md-none mb-3"
            placeholder="Search by Reservation, Customer, or Vehicle"
            v-model="searchQuery"
          />
          <p v-if="!reservations.length">
            Looks like you don't have access to any reservations! Contact your
            supervisor to get access.
          </p>
        </div>
      </div>
    </div>
    <div class="fluid-container">
      <!-- Reservations Table -->
      <div class="row g-0">
        <div class="col-12 mb-5" v-if="reservations.length">
          <div
            class="table-responsive"
            style="min-height: 65vh !important; max-height: 65vh !important"
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Platform Id</th>
                  <th>Vehicle</th>
                  <th>Pickup Time</th>
                  <th>Pickup Location</th>
                  <th>Return Time</th>
                  <th>Return Location</th>
                  <th>Renter</th>
                  <th>Phone</th>
                  <th>Balance</th>
                  <th>Status</th>
                  <th>Notes</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="reservation in sortedReservations"
                  :key="'reservation-' + reservation.platform_id"
                >
                  <td class="border align-middle text-center">
                    {{ reservation.platform_id }}
                  </td>
                  <td class="border align-middle text-nowrap">
                    <div
                      class="vehicle-card d-flex align-items-center"
                      v-if="reservation.vehicle_vin"
                    >
                      <img
                        :src="getVehicleImage(reservation.vehicle_model)"
                        class="rounded me-2"
                        alt="Vehicle Image"
                        style="height: 5vh"
                      />
                      <div class="d-flex flex-grow-1 align-items-center">
                        <span class="align-items-center flex-grow-1">
                          <h5
                            class="mb-0 clickable"
                            @click="copyToClipboard(reservation.vehicle_vin)"
                          >
                            {{
                              $store.state.user.vehicles.find(
                                (vehicle) =>
                                  vehicle.vin === reservation.vehicle_vin
                              )
                                ? $store.state.user.vehicles.find(
                                    (vehicle) =>
                                      vehicle.vin === reservation.vehicle_vin
                                  ).model
                                : ""
                            }}
                            <span v-if="reservation.vehicle_vin"
                              >({{ reservation.vehicle_vin.slice(-6) }})</span
                            >
                          </h5>
                          <p class="mb-0 mt-0" v-if="reservation.vehicle_vin">
                            {{ getVehicleLocation(reservation.vehicle_vin) }}
                          </p>
                          <p class="mb-0">
                            <span
                              class="badge badge-secondary me-1 clickable"
                              @click="
                                copyToClipboard(
                                  $store.state.user.vehicles.find(
                                    (vehicle) =>
                                      vehicle.vin === reservation.vehicle_vin
                                  )
                                    ? $store.state.user.vehicles.find(
                                        (vehicle) =>
                                          vehicle.vin ===
                                          reservation.vehicle_vin
                                      ).license_plate
                                    : ''
                                )
                              "
                            >
                              {{
                                $store.state.user.vehicles.find(
                                  (vehicle) =>
                                    vehicle.vin === reservation.vehicle_vin
                                )
                                  ? $store.state.user.vehicles.find(
                                      (vehicle) =>
                                        vehicle.vin === reservation.vehicle_vin
                                    ).license_plate
                                  : ""
                              }}
                            </span>
                            <span class="badge">
                              {{
                                $store.state.user.vehicles.find(
                                  (vehicle) =>
                                    vehicle.vin === reservation.vehicle_vin
                                )
                                  ? getRegionPrefix(
                                      $store.state.user.vehicles.find(
                                        (vehicle) =>
                                          vehicle.vin ===
                                          reservation.vehicle_vin
                                      ).region_id
                                    )
                                  : ""
                              }}
                            </span>
                            <span class="ms-1 badge badge-tertiary">{{
                              $store.state.user.vehicles.find(
                                (vehicle) =>
                                  vehicle.vin === reservation.vehicle_vin
                              )
                                ? $store.state.user.vehicles.find(
                                    (vehicle) =>
                                      vehicle.vin === reservation.vehicle_vin
                                  ).status
                                : ""
                            }}</span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td class="text-center border align-middle">
                    {{
                      new Date(reservation.pickup_at).toLocaleDateString(
                        "en-US",
                        {
                          timeZone: reservation.pickup_timezone,
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        }
                      )
                    }}
                  </td>
                  <td class="text-center border align-middle">
                    {{
                      $store.state.user.locations.find(
                        (l) => l.hq_id === reservation.pickup_location_id
                      )
                        ? $store.state.user.locations.find(
                            (l) => l.hq_id === reservation.pickup_location_id
                          ).name
                        : ""
                    }}
                  </td>
                  <td class="text-center border align-middle">
                    {{
                      new Date(reservation.return_at).toLocaleDateString(
                        "en-US",
                        {
                          timeZone: reservation.return_timezone,
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        }
                      )
                    }}
                  </td>
                  <td class="text-center border align-middle">
                    {{
                      $store.state.user.locations.find(
                        (l) => l.hq_id === reservation.return_location_id
                      )
                        ? $store.state.user.locations.find(
                            (l) => l.hq_id === reservation.return_location_id
                          ).name
                        : ""
                    }}
                  </td>
                  <td class="border align-middle">
                    {{ reservation.customer_first_name }}
                    {{ reservation.customer_last_name }}
                  </td>
                  <td class="border align-middle">{{ reservation.phone }}</td>
                  <td class="border align-middle">
                    {{
                      new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(reservation.payment_balance)
                    }}
                  </td>
                  <td class="border align-middle" style="min-width: 250px">
                    <select
                      class="form-control form-select w-100"
                      :value="reservation.status"
                      @change="onStatusChange($event, reservation)"
                    >
                      <option value="Pending">Pending</option>
                      <option value="On Rent">On Rent</option>
                      <option value="Returned">Returned</option>
                      <option value="Canceled">Canceled</option>
                    </select>
                  </td>
                  <td class="border align-middle" style="min-width: 250px">
                    <textarea
                      class="form-control"
                      @input="onNotesChange($event, reservation)"
                      :value="reservation.notes"
                    ></textarea>
                  </td>
                  <td class="text-center border align-middle">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      data-bs-toggle="offcanvas"
                      data-bs-target="
                                #reservationOffcanvas
                              "
                      @click="id = reservation.id"
                    >
                      View
                    </button>
                  </td>
                </tr>
                <tr>
                  <td
                    :colspan="12"
                    v-if="sortedReservations == 0 || !sortedReservations"
                  >
                    No results found.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Reservation View -->
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="reservationOffcanvas"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header align-middle">
          <h3 class="highlighted my-0 mt-0 fw-bold">View Reservation</h3>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            Close
          </button>
        </div>
        <div class="offcanvas-body">
          <ReservationOffcanvas :id="id"></ReservationOffcanvas>
        </div>
      </div>
      <!-- Filters -->
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="reservationsOffcanvas"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header align-middle">
          <h3 class="highlighted my-0 mt-0 fw-bold">Filters</h3>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            Close
          </button>
        </div>
        <div class="offcanvas-body">
          <h6 class="mt-3">Search By</h6>
          <!-- Search by Reservation, Customer, or Vehicle -->
          <label class="mt-3">Keywords</label>
          <input
            type="text"
            class="form-control w-100 mb-3"
            placeholder="Reservation, Customer, or Vehicle"
            v-model="searchQuery"
          />

          <div class="mt-3 mb-3"></div>
          <h6 class="mt-5">Sort By</h6>
          <label class="mt-3">Attribute</label>
          <!-- Sort By -->
          <select class="form-control form-select w-100" v-model="sorted">
            <option value="id">ID</option>
            <option value="platform_id">Platform ID</option>
            <option value="prefixed_id">Prefixed ID</option>
            <option value="platform_id">Platform</option>
            <option value="region_id">Region</option>
            <option value="status">Status</option>
            <option value="payment_balance">Owing</option>
            <option value="confirm_insurance">Insurance Status</option>
            <option value="confirm_license">License Status</option>
            <option value="vehicle_model">Vehicle Model</option>
            <option value="pickup_at">Pickup At</option>
            <option value="pickup_location_id">Pickup Location</option>
            <option value="return_at">Return At</option>
            <option value="return_location_id">Return Location</option>
          </select>
          <label class="mt-3">Order</label>
          <!-- Sort By -->
          <select class="form-control form-select w-100 mb-3" v-model="order">
            <option value="desc">Descending</option>
            <option value="asc">Ascending</option>
          </select>

          <h6 class="mt-5">Quick Actions</h6>
          <label class="mt-3">Pickups</label>
          <select
            class="form-control form-select w-100"
            @change="setPickupDateRange($event)"
            :value="pickup_qa"
          >
            <option value="">Select a Date</option>
            <option value="-1">Yesterday</option>
            <option value="0">Today</option>
            <option value="1">Tomorrow</option>
            <option value="2">In 2 Days</option>
            <option value="3">In 3 Days</option>
          </select>

          <label class="mt-3">Returns</label>
          <select
            class="form-control form-select w-100"
            @change="setReturnDateRange($event)"
            :value="return_qa"
          >
            <option value="">Select a Date</option>
            <option value="-1">Yesterday</option>
            <option value="0">Today</option>
            <option value="1">Tomorrow</option>
            <option value="2">In 2 Days</option>
            <option value="3">In 3 Days</option>
          </select>

          <h6 class="mt-5">Quality Assurance</h6>
          <button
            class="btn btn-danger w-100 mt-3"
            @click="
              () => {
                status = 'On Rent';
                return_before = new Date();
                sorted = 'return_at';
              }
            "
          >
            Show Overdue
          </button>

          <h6 class="mt-5">Filter By</h6>
          <!-- Filter by License Status -->
          <label class="mt-3">Filter by License Status</label>
          <select class="form-control form-select w-100" v-model="license">
            <option value="">All</option>
            <option value="true">Confirmed License</option>
            <option value="false">Awaiting Approval</option>
          </select>
          <!-- Filter by Insurance Status -->
          <label class="mt-3">Filter by Insurance Status</label>
          <select class="form-control form-select w-100" v-model="insurance">
            <option value="">All</option>
            <option value="true">Confirmed Insurance</option>
            <option value="false">Awaiting Approval</option>
          </select>
          <!-- Filter by Assigned -->
          <label class="mt-3">Filter by Vehicle Assignment</label>
          <select class="form-control form-select w-100" v-model="assignment">
            <option value="">All</option>
            <option value="Assigned">Assigned</option>
            <option value="Unassigned">Unassigned</option>
          </select>
          <!-- Filter by Status -->
          <label class="mt-3">Filter by Reservation Status</label>
          <select class="form-control form-select w-100" v-model="status">
            <option value="">All</option>
            <option value="Pending">Pending</option>
            <option value="On Rent">On Rent</option>
            <option value="Returned">Returned</option>
            <option value="Canceled">Canceled</option>
          </select>
          <!-- Filter by Pickup Location -->
          <label class="mt-3">Filter by Pickup Location</label>
          <select
            class="form-control form-select w-100"
            v-model="pickup_location"
          >
            <option value="">All</option>
            <option
              :value="location.hq_id"
              v-for="location of $store.state.user.locations.filter((l) => {
                return l.region_id == $store.state.user.region;
              })"
              :key="'filter-reservations-pickup-location-' + location.hq_id"
            >
              {{ location.name }}
            </option>
          </select>
          <!-- Filter by Return Location -->
          <label class="mt-3">Filter by Return Location</label>
          <select
            class="form-control form-select w-100"
            v-model="return_location"
          >
            <option value="">All</option>
            <option
              :value="location.hq_id"
              v-for="location of $store.state.user.locations.filter((l) => {
                return l.region_id == $store.state.user.region;
              })"
              :key="'filter-reservations-return-location-' + location.hq_id"
            >
              {{ location.name }}
            </option>
          </select>
          <h6 class="mt-5">Dates Between</h6>
          <!-- Pickup -->
          <label class="form-label mt-3">Pickup</label>
          <input
            type="date"
            class="form-control"
            name="date"
            v-model="pickup_after"
          />
          <p class="mt-3 text-center">to</p>
          <input
            type="date"
            class="form-control"
            name="date"
            v-model="pickup_before"
          />
          <!-- Return -->
          <label class="form-label mt-3">Return</label>
          <input
            type="date"
            class="form-control"
            name="date"
            v-model="return_after"
          />
          <p class="mt-3 text-center">to</p>
          <input
            type="date"
            class="form-control mb-5"
            name="date"
            v-model="return_before"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationOffcanvas from "@/components/offcanvas/ReservationOffcanvas.vue";

export default {
  name: "ReservationsView",
  data: function () {
    return {
      searchQuery: this.$route.query.platform_id
        ? this.$route.query.platform_id
        : "",
      assignment: "",
      region: "",
      return_location: "",
      pickup_location: "",
      status: "",
      insurance: "",
      license: "",
      sorted: "pickup_at",
      order: "desc",
      return_after: "",
      return_before: "",
      pickup_after: "",
      pickup_before: "",
      id: "", //For reservation
      pickup_qa: "",
      return_qa: "",
    };
  },
  components: {
    ReservationOffcanvas,
  },
  created: function () {
    this.debouncedUpdateNotes = this.lodash.debounce(this.updateNotes, 4000);
    this.debouncedUpdateStatus = this.lodash.debounce(this.updateStatus, 100);
  },
  methods: {
    updateStatus: function (reservation, status) {
      this.$axios
        .post(
          this.$store.state.root_url + "/reservation/status",
          {
            id: reservation.id,
            status: status,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateNotes: function (reservation, notes) {
      this.$axios
        .post(
          this.$store.state.root_url + "/reservation/notes",
          {
            id: reservation.id,
            notes: notes,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onNotesChange: function (event, reservation) {
      const newNotes = event.target.value;
      this.debouncedUpdateNotes(reservation, newNotes);
    },
    onStatusChange: function (event, reservation) {
      const newStatus = event.target.value;
      this.debouncedUpdateStatus(reservation, newStatus);
    },
    getVehicleImage(model) {
      return (
        this.$store.state.user.models.find((r) => r.name === model)
          ?.public_image_link || "default-image-link"
      );
    },
    getRegionPrefix(regionId) {
      return (
        this.$store.state.user.regions.find((r) => r.id === regionId)?.prefix ||
        ""
      );
    },
    getVehicleLocation(vin) {
      const location_id = this.$store.state.user.vehicles.find(
        (vehicle) => vehicle.vin === vin
      )
        ? this.$store.state.user.vehicles.find((vehicle) => vehicle.vin === vin)
            .location_id
        : null;

      if (location_id == null) {
        return null;
      }
      return this.$store.state.user.locations.find(
        (location) => location.id === location_id
      )
        ? this.$store.state.user.locations.find(
            (location) => location.id === location_id
          ).name
        : "";
    },
    setPickupDateRange(event) {
      const val = event.target.value;
      console.log(val);

      if (val !== "") {
        this.return_after = "";
        this.return_before = "";
        this.return_qa = "";

        const days = parseInt(event.target.value);
        this.pickup_qa = days;

        const timezone = this.$store.state.user.regions.find((r) => {
          return r.id == this.$store.state.region;
        }).timezone; // Replace with dynamic timezone if needed

        // Current date in the specified timezone
        let selectedDate = new Date(
          new Date().toLocaleString("en-US", { timeZone: timezone })
        );
        selectedDate.setDate(selectedDate.getDate() + days);

        this.pickup_after = this.formatDate(selectedDate);
        // Add 1 day in milliseconds, no need to adjust timezone again
        this.pickup_before = this.formatDate(
          new Date(selectedDate.getTime() + 86400000)
        );
        this.sorted = "pickup_at";
        this.order = "asc";
      } else {
        this.pickup_after = "";
        this.pickup_before = "";
        this.sorted = "pickup_at";
        this.order = "desc";
      }
    },
    setReturnDateRange(event) {
      const val = event.target.value;
      console.log(val);

      if (val !== "") {
        this.pickup_after = "";
        this.pickup_before = "";
        this.pickup_qa = "";

        const days = parseInt(event.target.value);
        this.return_qa = days;

        const timezone = this.$store.state.user.regions.find((r) => {
          return r.id == this.$store.state.region;
        }).timezone; // Replace with dynamic timezone if needed

        // Current date in the specified timezone
        let selectedDate = new Date(
          new Date().toLocaleString("en-US", { timeZone: timezone })
        );
        selectedDate.setDate(selectedDate.getDate() + days);

        this.return_after = this.formatDate(selectedDate);
        // Add 1 day in milliseconds, no need to adjust timezone again
        this.return_before = this.formatDate(
          new Date(selectedDate.getTime() + 86400000)
        );
        this.sorted = "return_at";
        this.order = "asc";
      } else {
        this.return_after = "";
        this.return_before = "";
        this.sorted = "return_at";
        this.order = "desc";
      }
    },
    formatDate(date) {
      // timeZone parameter removed as it's no longer needed here
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    reset() {
      this.searchQuery = "";
      this.assignment = "";
      this.region = "";
      this.return_location = "";
      this.pickup_location = "";
      this.status = "";
      this.insurance = "";
      this.license = "";
      this.sorted = "pickup_at";
      this.order = "desc";
      this.return_after = "";
      this.return_before = "";
      this.pickup_after = "";
      this.pickup_before = "";
      this.pickup_qa = "";
      this.return_qa = "";
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          // Optional: Display a message or perform an action to inform the user that the text has been copied
          console.log("Copied to clipboard:", text);
        })
        .catch((err) => {
          console.error("Error in copying text: ", err);
        });
    },
  },
  computed: {
    vehicles() {
      return this.$store.state.user.vehicles;
    },
    reservations() {
      return this.$store.state.user.reservations;
    },
    sortedReservations: function () {
      // Step 1a: Filter based on status filter
      let filteredReservations = this.reservations;
      if (this.assignment !== "") {
        if (this.assignment === "Assigned") {
          filteredReservations = this.reservations.filter((reservation) => {
            return reservation.vehicle_vin;
          });
        }
        if (this.assignment === "Unassigned") {
          filteredReservations = this.reservations.filter((reservation) => {
            return !reservation.vehicle_vin;
          });
        }
      }
      //Step 1b: Filter Status
      if (this.status !== "") {
        filteredReservations = filteredReservations.filter((reservation) => {
          return reservation.status === this.status;
        });
      }
      //Step 1c: Filter Region
      if (this.region !== "") {
        filteredReservations = filteredReservations.filter((reservation) => {
          return reservation.region_id === this.region;
        });
      }
      //Step 1b: Filter License Status
      if (this.license !== "") {
        const licenseBool = this.license === "true";
        filteredReservations = filteredReservations.filter((reservation) => {
          return reservation.confirm_license === licenseBool;
        });
      }
      //Step 1b: Filter Insurance Status
      if (this.insurance !== "") {
        const insuranceBool = this.insurance === "true";
        filteredReservations = filteredReservations.filter((reservation) => {
          return reservation.confirm_insurance === insuranceBool;
        });
      }
      //Step 1b: Filter Pickup Location
      if (this.pickup_location !== "") {
        filteredReservations = filteredReservations.filter((reservation) => {
          return reservation.pickup_location_id === this.pickup_location;
        });
      }
      //Step 1b: Filter Pickup Location
      if (this.return_location !== "") {
        filteredReservations = filteredReservations.filter((reservation) => {
          return reservation.return_location_id === this.return_location;
        });
      }

      // Filter Pickup Date Range
      if (this.pickup_after || this.pickup_before) {
        filteredReservations = filteredReservations.filter((reservation) => {
          const pickupDate = new Date(
            new Date(reservation.pickup_at).toLocaleDateString("en-US", {
              timeZone: reservation.pickup_timezone,
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
          );
          return (
            (!this.pickup_after || pickupDate >= new Date(this.pickup_after)) &&
            (!this.pickup_before || pickupDate <= new Date(this.pickup_before))
          );
        });
      }

      // Filter Return Date Range
      if (this.return_after || this.return_before) {
        filteredReservations = filteredReservations.filter((reservation) => {
          const returnDate = new Date(
            new Date(reservation.return_at).toLocaleDateString("en-US", {
              timeZone: reservation.return_timezone,
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
          );
          return (
            (!this.return_after || returnDate >= new Date(this.return_after)) &&
            (!this.return_before || returnDate <= new Date(this.return_before))
          );
        });
      }

      // Step 2: Apply search filter to the result
      const searchTerms = this.searchQuery.toLowerCase().trim().split(/\s+/);

      // Step 2: Determine sort iteratee based on the field type
      let iteratees;
      switch (this.sorted) {
        case "pickup_at":
        case "return_at":
          // For datetime fields, parse them as dates
          iteratees = [(r) => new Date(r[this.sorted])];
          break;
        default:
          // For other fields, use the value as is
          iteratees = [this.sorted];
          break;
      }

      // Filter reservations based on customer information or vehicle attributes
      return this.lodash.orderBy(
        filteredReservations.filter((reservation) => {
          const firstName = reservation.customer_first_name.toLowerCase();
          const lastName = reservation.customer_last_name.toLowerCase();
          const id = reservation.id.toString().toLowerCase();
          const platformId = reservation.platform_id.toLowerCase();
          const phone = reservation.phone.toLowerCase();
          const status = reservation.status.toLowerCase();
          const vehicleVin = reservation.vehicle_vin
            ? reservation.vehicle_vin.toLowerCase()
            : "";
          const vehicleModel = reservation.vehicle_model.toLowerCase();

          // Check if any term is included in the reservation or vehicle attributes
          return searchTerms.some(
            (term) =>
              firstName.includes(term) ||
              lastName.includes(term) ||
              id.includes(term) ||
              platformId.includes(term) ||
              phone.includes(term) ||
              vehicleVin.includes(term) ||
              status.includes(term) ||
              vehicleModel.includes(term)
          );
        }),
        iteratees,
        [this.order]
      );
    },
  },
};
</script>

<style scoped>
.sticky-column {
  position: sticky; /* Make it sticky on larger screens */
  position: -webkit-sticky; /* Compatibility for older webkit browsers */
  top: 0; /* Necessary for sticky positioning */
  left: 0;
  background-color: white;
  z-index: 2;
}

/* Media query for screens smaller than 768px (Bootstrap md breakpoint) */
@media (max-width: 768px) {
  .sticky-column {
    position: static; /* Non-sticky on small screens */
  }
}
.vehicle-card {
  min-width: 250px !important;
}

.table-responsive {
  overflow-x: auto;
}

.reservation-indicator {
  /* Styles for indicating a reservation on a specific day */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

th {
  white-space: nowrap; /* Prevent wrapping in table headers */
}

/* For sizes smaller than md (768px by default in Bootstrap 5.2) */
@media (max-width: 767.98px) {
  #reservationOffcanvas {
    width: 100% !important;
  }
}
#reservationOffcanvas {
  min-width: 75% !important;
}

thead th {
  position: sticky;
  top: 0;
  background-color: white; /* To ensure the header is not transparent */
  z-index: 1; /* To keep the header above other content */
}
.clickable {
  cursor: pointer;
}
</style>
