<template>
  <div>
    <div
      style="background: #d6faff; color: blue !important"
      class="fluid-container pt-1 pb-1"
    >
      <p class="my-auto text-center fw-bold" v-if="$store.state.authenticated">
        Select Region
        <select
          class="form-control form-select d-inline ms-2 select-region"
          :value="$store.state.user.region"
          @change="onRegionChange($event)"
          :disabled="isSelectDisabled"
        >
          <option
            :value="region.id"
            v-for="region in $store.state.user.regions"
            :key="'nav-region-' + region.id"
          >
            {{ region.name }}
          </option>
        </select>
      </p>
      <p class="my-auto text-center fw-bold" v-if="!$store.state.authenticated">
        Operations
      </p>
    </div>
    <div
      class="fluid-container pt-3 pb-3"
      style="background: #0000ff !important"
    >
      <div class="container">
        <div class="row g-0 d-flex">
          <div class="col-12">
            <nav class="navbar navbar-expand-md">
              <div class="container-fluid ps-0 pe-0">
                <div class="d-flex flex-row">
                  <router-link to="/">
                    <img
                      src="../assets/resla-logo-white-rgb-600px@300ppi.png"
                      height="15vh"
                    />
                  </router-link>
                </div>
                <div class="d-flex flex-row">
                  <button
                    class="btn btn-transparent p-0"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#nav-modal"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      style="height: 4.5vh"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z"
                        fill="#fff"
                      />
                    </svg>
                  </button>
                  <router-link
                    tag="button"
                    to="/get-started"
                    class="btn btn-secondary pr-1 ms-2 rounded-pill"
                    v-if="!$store.state.authenticated"
                  >
                    Get started
                  </router-link>

                  <button
                    v-on:click="logout"
                    class="btn btn-secondary pr-1 ms-2 rounded-pill"
                    v-if="$store.state.authenticated"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <!-- Menu-->
      <div
        class="modal fade"
        id="nav-modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style="min-height: 85vh !important">
          <div class="modal-content h-100 d-flex flex-column">
            <div class="modal-body d-flex flex-column justify-content-center">
              <!-- Home -->
              <router-link
                to="/"
                tag="a"
                class="mb-5 mt-5"
                v-if="$store.state.authenticated"
              >
                <h1 class="display-5 text-center">
                  <span class="highlighted my-auto">Home. </span>
                </h1>
              </router-link>
              <!-- Vehicles -->
              <router-link
                to="/vehicles"
                tag="a"
                class="mb-5"
                v-if="$store.state.authenticated"
              >
                <h1 class="display-5 text-center">
                  <span class="highlighted my-auto">Vehicles.</span>
                </h1>
              </router-link>
              <!-- Reservations -->
              <router-link
                to="/reservations"
                tag="a"
                class="mb-5"
                v-if="$store.state.authenticated"
              >
                <h1 class="display-5 text-center">
                  <span class="highlighted my-auto">Reservations.</span>
                </h1>
              </router-link>

              <!-- History -->
              <router-link
                to="/search"
                tag="a"
                class="mb-5"
                v-if="$store.state.authenticated"
              >
                <h1 class="display-5 text-center">
                  <span class="highlighted my-auto">Search.</span>
                </h1>
              </router-link>

              <!-- Dispatch -->
              <router-link
                to="/dispatch"
                tag="a"
                class="mb-5"
                v-if="$store.state.authenticated"
              >
                <h1 class="display-5 text-center">
                  <span class="highlighted my-auto">Dispatch.</span>
                </h1>
              </router-link>

              <!-- Schedule -->
              <router-link
                to="/schedule"
                tag="a"
                class="mb-5"
                v-if="$store.state.authenticated"
              >
                <h1 class="display-5 text-center">
                  <span class="highlighted my-auto">Schedule.</span>
                </h1>
              </router-link>

              <!-- Billing -->
              <router-link
                to="/billing"
                tag="a"
                class="mb-5"
                v-if="$store.state.authenticated"
              >
                <h1 class="display-5 text-center">
                  <span class="highlighted my-auto">Billing.</span>
                </h1>
              </router-link>

              <!-- Collections -->
              <router-link
                to="/collections"
                tag="a"
                class="mb-5"
                v-if="$store.state.authenticated"
              >
                <h1 class="display-5 text-center">
                  <span class="highlighted my-auto">Collections.</span>
                </h1>
              </router-link>

              <!-- Claims -->
              <router-link
                to="/claims"
                tag="a"
                class="mb-5"
                v-if="$store.state.authenticated"
              >
                <h1 class="display-5 text-center">
                  <span class="highlighted my-auto">Claims.</span>
                </h1>
              </router-link>

              <!-- Sales -->
              <router-link
                to="/sales"
                tag="a"
                class="mb-5"
                v-if="$store.state.authenticated"
              >
                <h1 class="display-5 text-center">
                  <span class="highlighted my-auto">Sales.</span>
                </h1>
              </router-link>

              <!-- Get Started -->
              <router-link
                to="/get-started"
                tag="a"
                class="mb-5"
                v-if="!$store.state.authenticated"
              >
                <h1 class="display-5 text-center">
                  <span class="highlighted my-auto">Get Started.</span>
                </h1>
              </router-link>

              <!-- Logout -->
              <router-link
                to="/"
                tag="a"
                v-on:click.native="logout"
                data-bs-dismiss="modal"
                class="mb-3"
                v-if="$store.state.authenticated"
              >
                <h1 class="display-5 text-center">
                  <span class="highlighted my-auto">Logout. </span>
                </h1>
              </router-link>
            </div>
            <div class="modal-footer mt-auto">
              <!-- mt-auto ensures the footer stays at the bottom -->
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  methods: {
    logout: function () {
      this.$store.commit("unsetAuthentication");
      this.$router.push("/get-started");
    },
    onRegionChange: function (event) {
      const newRegion = event.target.value;
      this.$store.commit("setRegion", parseInt(newRegion));
    },
  },
  computed: {
    isSelectDisabled: function () {
      return this.$store.state.loading;
    },
  },
};
</script>
<style scoped>
/* Default mobile styles */
.select-region {
  width: 50%; /* Full width on mobile */
}
/* Desktop styles */
@media (min-width: 768px) {
  .select-region {
    width: 25%; /* Adjust width for desktop */
  }
}
/* Large screens and above (large desktops) */
@media (min-width: 992px) {
  .select-region {
    width: 15%; /* Make it even smaller on large screens */
  }
}
</style>
